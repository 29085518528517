import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AssetFormValues } from '../../components/Forms/AssetForm/types';
import { AssetEmitter } from '../../events/AssetEmitter';
import { UpdateAsset } from '../../services/updateAsset';

export default function useUpdateAssetView(collectionUuid: string) {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateAsset = async (values: AssetFormValues) => {
    const { error, data } = await UpdateAsset(values.uuid, {
      collectionUuid,
      name: values.name,
      description: values.description,
      assetType: values.assetType,
      url: values.url,
      aiDescription: values.aiDescription,
      tags: values.tags,
      metadata: values.metadata,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Asset  Updated!', { variant: 'success' });
      closeModal();

      AssetEmitter.emit('AssetUpdated', null);
    }
  };

  return {
    handleUpdateAsset,
  };
}
