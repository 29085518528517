import CardInfo from 'src/components/shared/CardInfo/CardInfo';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { UserSessionGeneralStats } from 'src/lib/types/userSession';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';

interface UserSessionsStatsCardInfosProps {
  generalStatsData?: UserSessionGeneralStats;
}

export default function UserSessionsStatsCardInfos({
  generalStatsData,
}: UserSessionsStatsCardInfosProps) {
  return (
    <Flex gap={2} flexWrap={'wrap'} justifyContent={'center'}>
      <NumberInfo title="Total Sessions" total={generalStatsData?.count || 0} />
      <NumberInfo
        title="Unique Users"
        total={generalStatsData?.uniqueUsers || 0}
        decimals={0}
      />
      <CardInfo
        title="Total Session Time"
        text={formatMilliseconds(
          generalStatsData?.totalDuration || 0,
          'minutes'
        )}
      />{' '}
      <CardInfo
        title="Average Session Time"
        text={formatMilliseconds(
          generalStatsData?.averageDuration || 0,
          'minutes'
        )}
      />
      <CardInfo
        title="Total Session Time By User"
        text={formatMilliseconds(
          generalStatsData?.totalDurationByUser || 0,
          'minutes'
        )}
      />
    </Flex>
  );
}
