import SGMErrors from 'src/components/modules/MakerDialog/view/ListSGMErrors';
import Page from 'src/template/components/Page';

export default function SGMErrosPage() {
  return (
    <Page title="SGM Errors">
      <SGMErrors />
    </Page>
  );
}
