import { GameMakerAsset } from 'src/lib/types/gameMakerAsset';
import AssetForm from '../../components/Forms/AssetForm';
import useUpdateAssetView from './useView';

interface UpdateGameViewProps {
  asset: GameMakerAsset;
}

export default function UpdateAssetView({
  asset,
}: UpdateGameViewProps): JSX.Element {
  const { handleUpdateAsset } = useUpdateAssetView(asset.collectionUuid);

  return (
    <AssetForm
      defaultValues={{
        uuid: asset.uuid,
        name: asset.name,
        description: asset.description,
        assetType: asset.assetType,
        url: asset.url,
        aiDescription: asset.aiDescription,
        tags: asset.tags,
        metadata: asset.metadata,
      }}
      onSubmit={handleUpdateAsset}
      title="Update Asset "
    />
  );
}
