import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ConsumableEmitter } from '../../../events/ConsumableEmitter';
import { DeleteProduct } from '../../../services/deleteProduct';

export default function useDeleteConsumable() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteConsumable = async (uuid: string) => {
    const { data, error } = await DeleteProduct(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Consumable Deleted!', { variant: 'success' });
      closeModal();
      ConsumableEmitter.emit('ConsumableDeleted', data);
    }
  };

  return {
    handleDeleteConsumable,
  };
}
