import AssetCollectionForm from '../../components/Forms/AssetCollectionForm';
import useCreateAssetCollectionView from './useView';

export default function CreateAssetCollectionView(): JSX.Element {
  const { handleCreateAssetCollection } = useCreateAssetCollectionView();

  return (
    <AssetCollectionForm
      defaultValues={{
        name: '',
        description: '',
        creatorUuid: '',
        isPro: false,
        tags: [],
      }}
      onSubmit={handleCreateAssetCollection}
      title="Create Asset Collection"
    />
  );
}
