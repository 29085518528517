import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import { ProductProvider, RechargeType } from 'src/lib/types/product';
import { User } from 'src/lib/types/users';
import objectEntries from 'src/lib/utils/objectEntries';
import { SubscriptionFormValues } from './types';
import useGameFormView from './useView';

type Props = {
  defaultValues: SubscriptionFormValues;
  defaultUser?: User;
  onSubmit: (values: SubscriptionFormValues) => Promise<void>;
  title: string;
};

export default function SubscriptionForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: { control, handleSubmit, setValue },
    isSubmitting,
    handleCloseModal,
    hasFreeTrial,
  } = useGameFormView({ defaultValues });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb={2} spacing={1.5}>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="provider"
                  fullWidth
                  label="Provider"
                  select
                  wrapperProps={{
                    sx: {
                      gridColumn: '1 / span 2',
                    },
                  }}
                >
                  {objectEntries(ProductProvider).map(([label, value]) => (
                    <SelectOption value={value} key={value}>
                      {capitalCase(label)}
                    </SelectOption>
                  ))}
                </ControllerField>
                <ControllerField
                  control={control}
                  name="productId"
                  label="Product ID"
                  fullWidth
                  disabled={!!defaultValues?.productId}
                />
                <ControllerField
                  control={control}
                  name="providerProductId"
                  label="Provider Product ID"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="displayName"
                  label="Display Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <ControllerField
                  control={control}
                  name="priceUSD"
                  label="Price (USD)"
                  type="number"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="subscriptionDuration"
                  label="Subscription Duration (days)"
                  type="number"
                  fullWidth
                />

                <ControllerField
                  control={control}
                  name="rechargeType"
                  fullWidth
                  label="Asset Type"
                  select
                  wrapperProps={{
                    sx: {
                      gridColumn: '1 / span 2',
                    },
                  }}
                >
                  {objectEntries(RechargeType).map(([label, value]) => (
                    <SelectOption value={value} key={value}>
                      {capitalCase(label)}
                    </SelectOption>
                  ))}
                </ControllerField>
                <ControllerField
                  control={control}
                  name="rechargeValue"
                  label="Recharge Value"
                  type="number"
                  fullWidth
                />

                <Box p={1}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Is Daily?
                  </FormLabel>
                  <Controller
                    name="isDaily"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        {...field} // Conecta o RadioGroup ao `react-hook-form`
                      >
                        <FormControlLabel
                          value
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>

                <Box p={1}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Has Free Trial?
                  </FormLabel>
                  <Controller
                    name="hasFreeTrial"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        {...field} // Conecta o RadioGroup ao `react-hook-form`
                      >
                        <FormControlLabel
                          value
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
                {hasFreeTrial && (
                  <>
                    <ControllerField
                      control={control}
                      name="freeTrialDuration"
                      label="Free Trial Duration (days)"
                      type="number"
                      fullWidth
                    />
                    <ControllerField
                      control={control}
                      name="freeTrialRechargeValue"
                      label="Free Trial Recharge Value"
                      type="number"
                      fullWidth
                    />
                  </>
                )}
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
