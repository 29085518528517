import { Add, ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Box, Button, MenuItem } from '@mui/material';
import { useCallback, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import SectionDivider from 'src/components/shared/SectionDivider';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { Product, RechargeType } from 'src/lib/types/product';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { ConsumableEmitter } from '../../events/ConsumableEmitter';
import { fetchConsumables } from '../../services/fetchConsumables';
import CreateConsumableView from '../../view/Consumables/Create';
import DeleteConsumableView from '../../view/Consumables/Delete';
import UpdateConsumableView from '../../view/Consumables/Update';

export default function ConsumablesTableView(): JSX.Element {
  const openModal = useModalStore((state) => state.openModal);

  const { addParam, allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;

  const {
    data: consumables,
    isLoading,
    mutate,
  } = fetchConsumables({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ConsumableEmitter.on('ConsumableCreatedOrUpdated', refetchData);
    ConsumableEmitter.on('ConsumableDeleted', refetchData);

    return () => {
      ConsumableEmitter.off('ConsumableCreatedOrUpdated', refetchData);
      ConsumableEmitter.off('ConsumableDeleted', refetchData);
    };
  }, [refetchData]);

  const handleOpenCreateModal = () => {
    openModal(<CreateConsumableView />);
  };

  const handleOpenUpdateModal = (consumable: Product) => {
    openModal(<UpdateConsumableView consumable={consumable} />);
  };

  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteConsumableView uuid={uuid} />);
  };

  return (
    <>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <Flex gap={2} p={2} justifyContent={'flex-end'}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Consumable
          </Button>
        </Flex>
        <Box px={2}>
          <MagicTable
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              addParam('size', newPageSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              if (sortModel && sortModel.field && sortModel.sort) {
                addParam('orderBy', sortModel.field);
                addParam('order', sortModel.sort!);
              }
            }}
            rows={consumables?.body ?? []}
            hideNumberOfRows
            columns={[
              {
                field: 'produtId',
                headerName: 'Product ID',
                sortable: true,
                renderCell({ row }) {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{row.productId}</>
                  );
                },
              },
              {
                field: 'name',
                headerName: 'Name',
                sortable: true,
                renderCell({ row }) {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{row.name}</>
                  );
                },
              },
              {
                field: 'description',
                headerName: 'Description',
                sortable: true,
                renderCell({ row }) {
                  return (
                    row.description ?? (
                      <Label variant="ghost" color="default" flex={1}>
                        null
                      </Label>
                    )
                  );
                },
              },
              {
                field: 'provider',
                headerName: 'Provider',
                sortable: true,
              },
              {
                field: 'rechargeType',
                headerName: 'Recharge Type',
                sortable: true,
              },
              {
                field: 'rechargeValue',
                headerName: 'Recharge Value',
                sortable: true,
                renderCell({ row, value }) {
                  return row.rechargeType === RechargeType.STICKY_COIN
                    ? value / 1000
                    : value;
                },
              },
              {
                field: 'priceUSD',
                headerName: 'Price',
                sortable: true,
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                sortable: true,
                valueFormatter({ value }) {
                  return formatUTCDate(value);
                },
              },
              {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                flex: 0,

                renderCell({ row }) {
                  return (
                    <MoreMenu>
                      <CopyToClipboard text={row.uuid}>
                        <MenuItem aria-label="copy feed uuid">
                          <ContentCopy />
                          Copy UUID
                        </MenuItem>
                      </CopyToClipboard>
                      <MenuItem
                        onClick={() => {
                          handleOpenUpdateModal(row);
                        }}
                      >
                        <Edit />
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleOpenDeleteModal(row.uuid);
                        }}
                      >
                        <Delete />
                        Delete
                      </MenuItem>
                    </MoreMenu>
                  );
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'name',
};
