import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import UploadContent, {
  UploadTypes,
} from 'src/components/shared/UploadContent';
import { GameMakerAssetTypes } from 'src/lib/types/gameMakerAsset';
import objectEntries from 'src/lib/utils/objectEntries';
import { AssetFormValues } from './types';
import useAssetFormView from './useView';

type Props = {
  defaultValues: AssetFormValues;
  onSubmit: (values: AssetFormValues) => Promise<void>;
  title: string;
};

export default function AssetForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: { control, handleSubmit },
    isSubmitting,
    handleCloseModal,
    onUpdateContent,
    isUpdatingContent,
    onUpdateCover,
    isUpdatingCover,
    assetType,
    setValue,
  } = useAssetFormView({ defaultValues });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb={2} spacing={1.5}>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="description"
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <ControllerField
                  control={control}
                  name="aiDescription"
                  label="AI Description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <Controller
                  name="tags"
                  control={control}
                  defaultValue={defaultValues.tags || []}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      value={field.value || []}
                      onChange={(_, newValue) => {
                        const filteredTags = Array.from(
                          new Set(
                            newValue
                              .flatMap((tag) => tag.split('#')) // Separa cada entrada pelo "#"
                              .map((tag) => tag.replace(/,/g, '').trim()) // Remove caracteres especiais
                              .filter((tag) => tag !== '') // Remove tags vazias
                          )
                        );

                        setValue('tags', filteredTags);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={`#${option}`}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tags"
                          placeholder="Add a tag"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
                <ControllerField
                  control={control}
                  name="assetType"
                  fullWidth
                  label="Asset Type"
                  select
                  wrapperProps={{
                    sx: {
                      gridColumn: '1 / span 2',
                    },
                  }}
                >
                  {objectEntries(GameMakerAssetTypes).map(([label, value]) => (
                    <SelectOption value={value} key={value}>
                      {capitalCase(label)}
                    </SelectOption>
                  ))}
                </ControllerField>

                <Controller
                  name="url"
                  control={control}
                  render={({ field }) => (
                    <Stack spacing={2}>
                      <InputLabel htmlFor="my-input">Url</InputLabel>
                      <UploadContent
                        {...field}
                        type={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? UploadTypes.IMAGE
                            : UploadTypes.AUDIO
                        }
                        uploadButtonLabel={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? 'Upload Image'
                            : 'Upload Audio'
                        }
                        changeButtonLabel={
                          assetType == GameMakerAssetTypes.IMAGE
                            ? 'Change Image'
                            : 'Change Audio'
                        }
                        content={field.value ?? ''}
                        onEdit={(file) => {
                          onUpdateContent(file);
                        }}
                        onRemove={() => {
                          setValue('url', '');
                        }}
                        showEditButton
                        sx={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                          minHeight: '30px',
                          minWidth: '100px',
                        }}
                        isLoading={isUpdatingContent}
                      />
                    </Stack>
                  )}
                />
                {assetType === GameMakerAssetTypes.AUDIO && (
                  <Controller
                    name="metadata.coverImage"
                    control={control}
                    render={({ field }) => (
                      <Stack spacing={2}>
                        <InputLabel htmlFor="my-input">Cover Image</InputLabel>
                        <UploadContent
                          {...field}
                          type={UploadTypes.IMAGE}
                          uploadButtonLabel={'Upload Image'}
                          changeButtonLabel={'Change Image'}
                          content={field.value ?? ''}
                          onEdit={(file) => {
                            onUpdateCover(file);
                          }}
                          onRemove={() => {
                            onUpdateCover('');
                          }}
                          showEditButton
                          sx={{
                            maxWidth: '300px',
                            maxHeight: '300px',
                            minHeight: '30px',
                            minWidth: '100px',
                          }}
                          isLoading={isUpdatingCover}
                        />
                      </Stack>
                    )}
                  />
                )}
                <Accordion>
                  <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                    {'Metadata'}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={2}>
                      <Controller
                        name="json"
                        control={control}
                        defaultValue={JSON.stringify(
                          defaultValues.metadata || {},
                          null,
                          2
                        )}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Metadata"
                            placeholder='{ "key": "value" }'
                            multiline
                            rows={6}
                            fullWidth
                            variant="outlined"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ''}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              field.onChange(inputValue);

                              try {
                                setValue('metadata', JSON.parse(inputValue));
                              } catch {
                                // O erro já será tratado pelo Yup, então não precisa de setError()
                              }
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
