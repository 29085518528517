import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ProductType } from 'src/lib/types/product';
import { ConsumableFormValues } from '../../../components/Forms/ConsumablesForm/types';
import { ConsumableEmitter } from '../../../events/ConsumableEmitter';
import { CreateOrUpdateProduct } from '../../../services/createProduct';

export default function useCreateConsumableView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateConsumable = async (values: ConsumableFormValues) => {
    const { error, data } = await CreateOrUpdateProduct({
      ...values,
      type: ProductType.CONSUMABLE,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Consumable Updated!', { variant: 'success' });
      closeModal();

      ConsumableEmitter.emit('ConsumableCreatedOrUpdated', data);
    }
  };

  return {
    handleCreateConsumable,
  };
}
