import { ProductProvider, RechargeType } from 'src/lib/types/product';
import ConsumableForm from '../../../components/Forms/ConsumablesForm';
import useCreateConsumableView from './useView';

export default function CreateConsumableView(): JSX.Element {
  const { handleCreateConsumable } = useCreateConsumableView();

  return (
    <ConsumableForm
      defaultValues={{
        productId: '',
        providerProductId: '',
        name: '',
        description: '',
        displayName: '',
        priceUSD: 0,
        provider: ProductProvider.APPLE,
        rechargeType: RechargeType.STICKY_COIN,
        rechargeValue: 0,
      }}
      onSubmit={handleCreateConsumable}
      title="Create Consumable"
    />
  );
}
