import { DeepPartial } from 'react-hook-form';
import { User } from 'src/lib/types/users';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    active: string | boolean;
    name: any;
    slug: any;
    email: string;
    updatedAt: {
      gte: string;
      lte: string;
    };
    lastSession: {
      gte: string;
      lte: string;
    };
  };
};
export const fetchUsers = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<User[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<User[], Error>(`admin/users?${parsedParams}`);

  return response;
};
