import { Download, FilterList } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Badge,
  Box,
  Button,
  Card,
  CardProps,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { DEFAULT_ROWS_OPTIONS } from 'src/lib/constants/pagination';
import {
  BracketFilterParams,
  CustomFilters,
} from 'src/lib/types/bracketFilterParams';
import { LabelValue } from 'src/lib/types/labelValue';
import Scrollbar from 'src/template/components/Scrollbar';
import {
  TableHeadCustom,
  TableSelectedActions,
} from 'src/template/components/table';
import Center from '../Center';
import Flex from '../Flex';
import Modal from '../Modal';
import SmartTableTextInput from './SmartTableTextInput';

export type SmartColumn = {
  id: string;
  label: string;
  isSortable: boolean;
  sx?: any;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  searchable?: boolean;
};

type SmartTableProps = {
  data: any[] | undefined;
  total: number;
  loading: boolean;
  children: ReactNode;
  columns: SmartColumn[];
  filter: BracketFilterParams;
  setFilter: (filter: Partial<BracketFilterParams>) => void;
  searchForOptions: LabelValue[];
  tabs?: LabelValue[];
  onChangeTab?: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => void;
  activeTab?: string;
  filterModal?: ReactNode;
  selected?: string[];
  selectedActions?: ReactNode;
  onSelectAll?: (checked: boolean) => void;
  downloadable?: boolean;
  onDownload?: () => void;
  onApplyFilters?: () => void;
  menu?: ReactNode;
  menuAfterSearch?: boolean;
  cardProps?: CardProps;
};

export default function SmartTable({
  data,
  total,
  loading,
  children,
  columns,
  filter,
  setFilter,
  searchForOptions,
  tabs,
  activeTab,
  onChangeTab,
  filterModal,
  selected,
  selectedActions,
  onSelectAll,
  downloadable,
  onDownload,
  onApplyFilters,
  menu,
  menuAfterSearch = false,
  cardProps,
}: SmartTableProps) {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const showTabs = tabs && activeTab && onChangeTab;
  const showModalButton = filterModal;
  const showCheckbox = selectedActions && selected && onSelectAll;

  const onSort = (column: string) => {
    const isSortable = columns.find((c) => c.id === column)?.isSortable;

    if (isSortable) {
      setFilter({
        order:
          filter.orderBy === column
            ? filter.order === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc',
        orderBy: column,
      });
    }
  };

  const handleRemoveFilters = () => {
    setFilter({
      ...filter,
      custom: {},
    });
  };

  return (
    <Card
      {...cardProps}
      sx={
        searchForOptions.length === 0
          ? {
              paddingTop: '0.5rem',
            }
          : {}
      }
    >
      {showTabs && (
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={onChangeTab}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      )}
      {searchForOptions.length > 0 && (
        <Flex
          gap={2}
          flexDirection={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
          flexWrap={'wrap'}
        >
          {!menuAfterSearch && menu}
          <Stack direction={'row'} width={'100%'} spacing={2}>
            <TextField
              fullWidth
              select
              label="Search for"
              value={filter.searchFor}
              onChange={(e) => setFilter({ searchFor: e.target.value })}
              SelectProps={{
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                },
              }}
              sx={{
                maxWidth: { sm: 240 },
                textTransform: 'capitalize',
              }}
            >
              {searchForOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <SmartTableTextInput
              defaultValue={filter.query}
              setValue={(value) => setFilter({ query: value })}
            />
          </Stack>
          {menuAfterSearch && menu}
          {showModalButton && (
            <Stack>
              <IconButton
                aria-label="cart"
                sx={{ height: 'fit-content', alignSelf: 'center' }}
                onClick={() => setShowFilterModal(true)}
              >
                <Badge
                  badgeContent={Object.values(
                    filter.custom as CustomFilters
                  ).reduce(
                    (prev, operators) =>
                      prev +
                      (Object.values(operators)?.reduce(
                        (p, val) => p + (val ? 1 : 0),
                        0
                      ) || 0),
                    0
                  )}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton>
              <Modal
                open={showFilterModal}
                onClose={() => setShowFilterModal(false)}
              >
                <Card
                  sx={{
                    p: 3,
                    position: 'relative',
                  }}
                >
                  <IconButton
                    aria-label="Close modal"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                    onClick={() => setShowFilterModal(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  {filterModal}
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Button color="error" onClick={handleRemoveFilters}>
                      Remove Filters
                    </Button>
                    {onApplyFilters && (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={onApplyFilters}
                        disabled={false}
                      >
                        Apply
                      </Button>
                    )}
                  </Stack>
                </Card>
              </Modal>
            </Stack>
          )}
          {downloadable && (
            <IconButton
              sx={{
                height: 'fit-content',
                alignSelf: 'center',
              }}
              onClick={onDownload}
            >
              <Download />
            </IconButton>
          )}
        </Flex>
      )}

      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: '100%',
            position: 'relative',
            fontVariantNumeric: 'tabular-nums',
          }}
        >
          {showCheckbox && selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected.length}
              rowCount={data?.length || 0}
              onSelectAllRows={onSelectAll}
              actions={selectedActions}
            />
          )}

          <Table size="medium">
            <TableHeadCustom
              order={filter.order}
              orderBy={filter.orderBy}
              headLabel={columns}
              rowCount={loading ? 0 : data && data.length}
              numSelected={selected?.length || 0}
              onSort={onSort}
              onSelectAllRows={onSelectAll}
            />
            <TableBody>
              {loading || !data ? (
                <TableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <Center>
                      <CircularProgress />
                    </Center>
                  </TableCell>
                </TableRow>
              ) : (
                data && children
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_OPTIONS}
          component="div"
          count={total ?? 0}
          rowsPerPage={filter.size}
          page={filter.page}
          onPageChange={(e, page) => setFilter({ page })}
          onRowsPerPageChange={(e) =>
            setFilter({ size: parseInt(e.target.value, 10), page: 0 })
          }
        />
      </Box>
    </Card>
  );
}
