import UserSessions from 'src/components/modules/UserSessions/view/ListUserSessions';
import Page from 'src/template/components/Page';

export default function UsersSessionsPage() {
  return (
    <Page title="Users Sessions">
      <UserSessions />
    </Page>
  );
}
