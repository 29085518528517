import { DeepPartial } from 'react-hook-form';
import { sgmFetcher } from 'src/lib/services/sgm/fetcher';
import { SGMChatMessageError } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type ChatErrorsResponse = {
  rows: SGMChatMessageError[];
  count: number;
};

export type PossibleParams = {
  messageId: string;
  page: number;
  size: number;
  startDate: string;
  endDate: string;
};

export const fetchMessageErrors = (
  params?: DeepPartial<PossibleParams>
): SWRResponse<ChatErrorsResponse> => {
  const limit = params?.size || 10;
  const offset = params?.page ? params.page * limit : 0;
  const parsedParams = parseSearchParams({
    ...params,
    page: undefined,
    size: undefined,
    offset,
    limit,
    filter: {
      messageId: params?.messageId,
    },
  });
  const response = useSWR<ChatErrorsResponse, Error>(
    `/api/admin/chat-errors?${parsedParams}`,
    sgmFetcher
  );

  return response;
};
