import { useParams } from 'react-router';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchUsersSessions } from '../../services/fetchUsersSessions';

export default function useUsersSessions() {
  const { uuid: userUuid } = useParams<{
    uuid?: string;
  }>();

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,

    userSearchBy: allSearchParams.userSearchBy ?? initialParams.userSearchBy,
    userQuery: allSearchParams.userQuery ?? initialParams.userQuery,

    gameSearchBy: allSearchParams.gameSearchBy ?? initialParams.gameSearchBy,
    gameQuery: allSearchParams.gameQuery ?? initialParams.gameQuery,
  };

  const { data, isLoading } = fetchUsersSessions({
    page: params.page,
    size: params.size,
    order: {
      [params.orderBy]: params.sortOrder,
    },
    filter: {
      createdAt: {
        ...(params.startDate && {
          gte: `${params.startDate}T00:00:00.000Z`,
        }),
        ...(params.endDate && {
          lte: `${params.endDate}T23:59:59.999Z`,
        }),
      },
      ...(userUuid && {
        'user.uuid': userUuid,
      }),

      ...(params.userSearchBy &&
        params.userQuery && {
          [params.userSearchBy]: {
            like: `%${params.userQuery}%`,
          },
        }),

      ...(params.gameSearchBy &&
        params.gameQuery && {
          [params.gameSearchBy]: {
            like: `%${params.gameQuery}%`,
          },
        }),
    },
  });

  return {
    data,
    isLoading,
    params,
    userUuid,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',

  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.dateTime.split('T')[0],

  userSearchBy: '',
  userQuery: '',

  gameSearchBy: '',
  gameQuery: '',
};

export type FetchUsersSessionsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
  startDate: string;
  endDate: string;

  userSearchBy?: string;
  userQuery?: string;

  gameSearchBy?: string;
  gameQuery?: string;
};
