/* eslint-disable no-promise-executor-return */
import { ContentCopy, PlayCircle } from '@mui/icons-material';
import { Box, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';

import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import { LabelValue } from 'src/lib/types/labelValue';
import Routes from 'src/routes/Routes';
import Image from 'src/template/components/Image';

import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FeedItemAnalyticsResponse } from 'src/components/modules/FeedAnalytics/services/fetchFeedItemAnalytics';
import { reviewStatusColumns } from 'src/components/modules/Review/reviewStatusColumn';
import MagicTableChannelCell from 'src/components/shared/MagicTable/ChannelCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import {
  FeedItem,
  FeedItemContentTypes,
  FeedItemTypes,
} from 'src/lib/types/FeedItem';
import { ChannelContent } from 'src/lib/types/channelContent';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import { FetchChannelContentQueryParams } from '../../view/List/useView';
import useChannelContentTable from './useView';

type ChannelContentTableProps = {
  data?: FeedItemAnalyticsResponse;
  isLoading: boolean;
  params: Pick<
    FetchChannelContentQueryParams,
    'page' | 'size' | 'orderBy' | 'sortOrder'
  >;
  onChangeChannelContentStatus: (
    uuid: string,
    channelUuid: string,
    currentStatus: 'active' | 'inactive'
  ) => Promise<void>;
};

export default function ChannelContentTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  onChangeChannelContentStatus,
}: ChannelContentTableProps) {
  const {
    handleOpenSendToAppVideoFeedModal,
    handleChannelContentStatusChange,
    isChangingStatus,
    rowUuidChangingStatus,
  } = useChannelContentTable(onChangeChannelContentStatus);
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <Box px={2}>
        <MagicTable
          rowHeight={64}
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data?.body ?? []}
          rowCount={data?.meta.totalCount ?? 0}
          columns={[
            {
              field: 'channel',
              headerName: 'Channel',
              sortable: false,
              flex: 2 / 3,
              renderCell({ row }) {
                return row.channel ? (
                  <MagicTableChannelCell channel={row.channel} />
                ) : (
                  '--'
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              align: 'center',
              flex: 0,
              renderCell({ row }) {
                const channelContentUuid = row?.creatorChannelContent?.uuid;
                const status = row?.creatorChannelContent?.adminStatus;
                const active = status === 'active';
                return (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                  >
                    <Switch
                      checked={active}
                      color="success"
                      onChange={() =>
                        handleChannelContentStatusChange(
                          channelContentUuid,
                          row.channel?.uuid,
                          status as 'active' | 'inactive'
                        )
                      }
                      size="small"
                      disabled={
                        (isChangingStatus &&
                          rowUuidChangingStatus === channelContentUuid) ||
                        isLoading
                      }
                    />
                    <Box textAlign="center" fontSize={12}>
                      (
                      {channelContentUuid &&
                      rowUuidChangingStatus === channelContentUuid
                        ? 'Changing...'
                        : status}
                      )
                    </Box>
                  </Stack>
                );
              },
            },
            {
              field: 'title',
              headerName: 'Title',
              sortable: false,
              renderCell({ row }: { row: FeedItem }) {
                return (
                  <Link to={Routes.channel.content(row.uuid)}>
                    {row.title ? row.title : '--'}
                  </Link>
                );
              },
            },
            {
              field: 'content',
              headerName: 'Content',
              sortable: false,
              renderCell({ row }: { row: FeedItem }) {
                if (
                  row.contentType === FeedItemContentTypes.video &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <PlayCircle />
                        Play Video
                      </Flex>
                    </Link>
                  );

                if (
                  row.contentType === FeedItemContentTypes.image &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <LinkIcon />
                        Show Image
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No content found
                  </Typography>
                );
              },
            },
            ...reviewStatusColumns.map((column) => ({
              ...column,
              renderCell: ({ row }: { row: any }) =>
                row.creatorChannelContent
                  ? column.renderCell({
                      row: row.creatorChannelContent as ChannelContent,
                    })
                  : null,
              flex: 1,
            })),
            {
              field: 'item',
              headerName: 'Item',
              sortable: false,
              renderCell({ row }: { row: FeedItem }) {
                if (row.itemType === FeedItemTypes.game && row.game)
                  return (
                    <Link to={Routes.game_center.game.view(row.game.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.game.versions?.length && (
                          <Image
                            src={row.game.versions[0].imageUrl}
                            alt={row.game.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.game.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.collection && row.collection)
                  return (
                    <Link to={Routes.collection(row.collection.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.collection.coverFileUrl && (
                          <Image
                            src={row.collection.coverFileUrl}
                            alt={row.collection.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.collection.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.nft && row.nft)
                  return (
                    <Link to={Routes.nft(row.nft.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.nft.fileUrl && (
                          <Image
                            src={row.nft.fileUrl}
                            alt={row.nft.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.nft.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.club && row.club)
                  return (
                    <Link
                      to={Routes.comunity.club(row.club.uuid)}
                      target="_blank"
                    >
                      <Flex gap={2} alignItems="center">
                        <LinkIcon />
                        {row.club.name}
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No item
                  </Typography>
                );
              },
            },
            {
              field: 'views',
              headerName: 'Views',
              flex: 1 / 2,
              sortable: true,
            },
            {
              field: 'uniqueUsersViewed',
              headerName: 'Users',
              sortable: true,
              flex: 1 / 2,
            },
            {
              field: 'viewsPerUser',
              headerName: 'Views/User',
              sortable: true,
            },
            {
              field: 'secondsWatched',
              headerName: 'Time Watched',
              sortable: true,
              valueGetter: (params) =>
                formatMilliseconds(params.value * 1000, 'minutes'),
            },
            {
              field: 'clicks',
              headerName: 'Clicks',
              sortable: true,
              flex: 1 / 2,
            },
            {
              field: 'clickRate',
              headerName: 'Click Rate',
              sortable: true,
              valueFormatter: ({ value }) => `${value.toFixed(2)}%`,
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    <MenuItem
                      aria-label="send to feed"
                      onClick={() =>
                        handleOpenSendToAppVideoFeedModal(row.uuid)
                      }
                    >
                      <PlayCircle />
                      Send to Feed
                    </MenuItem>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Creator',
    value: 'creator',
  },
  {
    label: 'Item Name',
    value: 'itemName',
  },
];
