import { Product, RechargeType } from 'src/lib/types/product';
import SubscriptionForm from '../../../components/Forms/SubscriptionsForm';
import useCreateSubscriptionView from '../Create/useView';

interface UpdateSubscriptionViewProps {
  subscription: Product;
}

export default function UpdateSubscriptionView({
  subscription: {
    productId,
    providerProductId,
    name,
    description,
    displayName,
    priceUSD,
    provider,
    rechargeType,
    rechargeValue,
    isDaily,
    freeTrialDuration,
    freeTrialRechargeValue,
    hasFreeTrial,
    subscriptionDuration,
  },
}: UpdateSubscriptionViewProps): JSX.Element {
  const { handleCreateSubscription } = useCreateSubscriptionView();

  return (
    <SubscriptionForm
      defaultValues={{
        productId,
        providerProductId,
        name,
        description: description || '',
        displayName: displayName || '',
        priceUSD: +priceUSD || 0,
        provider,
        rechargeType: rechargeType || RechargeType.STICKY_COIN,
        rechargeValue: rechargeValue || 0,
        isDaily: isDaily ? 'true' : 'false',
        freeTrialDuration: freeTrialDuration || 0,
        freeTrialRechargeValue: freeTrialRechargeValue || 0,
        hasFreeTrial: hasFreeTrial ? 'true' : 'false',
        subscriptionDuration: subscriptionDuration || 0,
      }}
      onSubmit={handleCreateSubscription}
      title="Update Subscription"
    />
  );
}
