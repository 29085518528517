import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { SubscriptionEmitter } from '../../../events/SubscriptionEmitter';
import { DeleteProduct } from '../../../services/deleteProduct';

export default function useDeleteSubscription() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteSubscription = async (uuid: string) => {
    const { data, error } = await DeleteProduct(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Subscription Deleted!', { variant: 'success' });
      closeModal();
      SubscriptionEmitter.emit('SubscriptionDeleted', data);
    }
  };

  return {
    handleDeleteSubscription,
  };
}
