import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateAssetInput = {
  name: string;
  description?: string;
  aiDescription?: string;
  url: string;
  assetType: string;
  collectionUuid: string;
  tags?: string[];
  metadata?: object;
};

export async function UpdateAsset(
  uuid: string,
  data: UpdateAssetInput
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/game-maker-asset/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
