import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import SGMErrorsTable from '../../components/SGMErrorsTable/SGMErrorsTable';
import useSGMErrors from './useView';

interface ISGMErrorsProps {
  showHeader?: boolean;
}

export default function SGMErrors({ showHeader = true }: ISGMErrorsProps) {
  const { data, isLoading, params } = useSGMErrors();
  return (
    <Container maxWidth="xl">
      {showHeader && (
        <HeaderBreadcrumbs
          heading="SGM Errors"
          links={[{ name: 'SGM Errors' }]}
        />
      )}
      <SGMErrorsTable
        data={data?.rows ?? []}
        totalCount={data?.count ?? 0}
        isLoading={isLoading}
        params={params}
      />
    </Container>
  );
}
