import { ContentCopy, Info } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMChatMessageError } from 'src/lib/types/sgmChatMessage';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import { FetchSGMErrorsQueryParams } from '../../view/ListSGMErrors/useView';
import SGMMessageLogModal from '../../view/logModal';

type SGMErrorsTableProps = {
  data?: SGMChatMessageError[];
  totalCount: number;
  isLoading: boolean;
  params: FetchSGMErrorsQueryParams;
  showGameFilter?: boolean;
  showUserFilter?: boolean;
};

export default function SGMErrorsTable({
  data,
  totalCount,
  isLoading,
  params: { page, size, startDate, endDate },
}: SGMErrorsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  // Modal

  const { openModal } = useModalStore();

  const handleOpenInfo = (messageId: string) => {
    openModal(<SGMMessageLogModal messageUuid={messageId} title="Logs" />);
  };

  return (
    <Card>
      <Flex gap={2} p={2} justifyContent={'flex-end'} alignItems={'start'}>
        <TextField
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          defaultValue={startDate}
          onChange={(e) => {
            const { value } = e.target;
            if (value) {
              debouncedChangeField(value, 'startDate');
            } else removeParam('startDate');
          }}
        />
        <TextField
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          defaultValue={endDate}
          onChange={(e) => {
            const { value } = e.target;
            if (value) {
              debouncedChangeField(value, 'endDate');
            } else removeParam('endDate');
          }}
        />
      </Flex>
      <CardContent>
        <MagicTable
          rows={data ?? []}
          rowCount={totalCount}
          getRowId={(r) => r.id}
          loading={isLoading}
          paginationMode="server"
          pageSize={size}
          page={page}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('sortOrder', currentModel.sort);
            } else {
              removeParam('sortOrder');
              removeParam('orderBy');
            }
          }}
          columns={[
            {
              field: 'error',
              headerName: 'Error Message',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                const errorMessage = row?.error?.message;

                return (
                  <Tooltip title={errorMessage} arrow>
                    <Box
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer',
                      }}
                    >
                      <Typography variant="body2">{errorMessage}</Typography>
                    </Box>
                  </Tooltip>
                );
              },
            },
            {
              field: 'chatId',
              headerName: 'Chat',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }) {
                return (
                  <Link
                    to={Routes.sgm.dialog(row.chatId)}
                    color={'inherit'}
                    underline="hover"
                  >
                    {row?.chatId}
                  </Link>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              flex: 1 / 4,
              sortable: false,
              valueFormatter({ value }) {
                return formatUTCDate(value);
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Flex>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenInfo(row.messageId)}
                    >
                      <Info />
                    </IconButton>
                    <MoreMenu>
                      <CopyToClipboard text={row.id}>
                        <MenuItem aria-label="Copy ID">
                          <ContentCopy />
                          Copy UUID
                        </MenuItem>
                      </CopyToClipboard>
                    </MoreMenu>
                  </Flex>
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
