import { User } from './users';

export type GameMakerAsset = {
  uuid: string;

  name: string;
  description?: string;
  aiDescription?: string;
  assetType: GameMakerAssetTypes;
  url: string;
  collectionUuid: string;
  creatorUuid?: string;
  creator?: User;
  isPro: boolean;
  tags: string[];
  metadata?: object;

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export enum GameMakerAssetTypes {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
}

export type GameMakerAssetCollection = {
  uuid: string;

  name: string;
  description?: string;
  assetsCount: number;
  assets?: GameMakerAsset[];
  creatorUuid: string;
  creator?: User;
  isPro: boolean;
  tags: string[];

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};
