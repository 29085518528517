import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Card, CardContent, MenuItem, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import {
  UserSessionGeneralStats,
  UserSessionStats,
} from 'src/lib/types/userSession';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import Routes from 'src/routes/Routes';
import { FetchUserSessionsStatsQueryParams } from '../../view/ListUserSessionsStats/useView';
import UserSessionsStatsCardInfos from '../UserSessionsStatsCardInfos/UserSessionsStatsCardInfos';

type PlayerTableProps = {
  data?: UserSessionStats[];
  totalCount?: number;
  isLoading: boolean;
  params: FetchUserSessionsStatsQueryParams;
  generalStatsData?: UserSessionGeneralStats;
};

export default function UserSessionsStatsTable({
  data,
  isLoading,
  params: { page, size, startDate, endDate },
  totalCount,
  generalStatsData,
}: PlayerTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  return (
    <Card>
      <Stack width={'100%'} px={2}>
        <UserSessionsStatsCardInfos generalStatsData={generalStatsData} />
        <Flex gap={1} justifyContent={'flex-end'} p={2}>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={startDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'startDate');
              } else removeParam('startDate');
            }}
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={endDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'endDate');
              } else removeParam('endDate');
            }}
          />
        </Flex>
      </Stack>
      <CardContent>
        <MagicTable
          rows={data ?? []}
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          rowCount={totalCount ?? 0}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          getRowId={(row) => row.userUuid}
          columns={[
            {
              field: 'user',
              headerName: 'User',
              flex: 1,
              renderCell({ row }) {
                return (
                  <MagicTableUserCell
                    user={row.user}
                    linkUrl={`${Routes.user(row.userUuid)}/?tab=Sessions`}
                  />
                );
              },
            },
            {
              field: 'count',
              headerName: 'Sessions',
              flex: 1 / 2,
            },
            {
              field: 'totalDuration',
              headerName: 'Total Session Time',
              valueFormatter({ value }) {
                return formatMilliseconds(value);
              },
              flex: 1,
            },
            {
              field: 'averageDuration',
              headerName: 'Average Session Time',
              valueFormatter({ value }) {
                return formatMilliseconds(value);
              },
              flex: 1,
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>

                    <Link
                      to={`${Routes.game_center.game.view(
                        row.gameUuid
                      )}/?tab=sessions`}
                    >
                      <MenuItem aria-label="view game">
                        <OpenInNew />
                        View Game Sessions
                      </MenuItem>
                    </Link>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
