import { Container } from '@mui/material';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import UserSessionsTable from '../../components/UserSessionsTable/UserSessionsTable';
import useUserSessions from './useView';

interface IUserSessionsProps {
  showHeader?: boolean;
}

export default function UserSessions({
  showHeader = true,
}: IUserSessionsProps) {
  const { data, isLoading, params, userUuid } = useUserSessions();
  return (
    <Container maxWidth="xl">
      {showHeader && (
        <HeaderBreadcrumbs
          heading="App Sessions"
          links={[
            ...(userUuid ? [{ name: 'Users', href: Routes.users.list }] : []),
            { name: 'Users Sessions' },
          ]}
        />
      )}
      <UserSessionsTable
        data={data?.rows ?? []}
        totalCount={data?.count ?? 0}
        isLoading={isLoading}
        params={params}
        showUserFilter={!userUuid}
      />
    </Container>
  );
}
