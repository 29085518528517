import UserSessionsStats from 'src/components/modules/UserSessions/view/ListUserSessionsStats';
import Page from 'src/template/components/Page';

export default function UserSessionsAnalyticsPage() {
  return (
    <Page title="App Sessions Analytics">
      <UserSessionsStats />
    </Page>
  );
}
