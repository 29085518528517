import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import {
  Product,
  ProductProvider,
  ProductType,
  RechargeType,
} from 'src/lib/types/product';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateOrUpdateProductInput = {
  productId: string;
  providerProductId: string;
  provider: ProductProvider;
  type: ProductType;
  name: string;
  displayName: string;
  description: string;
  priceUSD: number;
  rechargeType: RechargeType;

  // Subscriptions
  hasFreeTrial?: boolean | null;
  isDaily?: boolean;
  subscriptionDuration?: number | null;
  freeTrialDuration?: number | null;
  freeTrialRechargeValue?: number | null;
};

export async function CreateOrUpdateProduct(
  data: CreateOrUpdateProductInput
): Promise<ServiceResponse<Product>> {
  try {
    const formattedData: CreateOrUpdateProductInput = {
      ...data,
      freeTrialDuration: data.hasFreeTrial ? data.freeTrialDuration : null,
      freeTrialRechargeValue: data.hasFreeTrial
        ? data.freeTrialRechargeValue
        : null,
    };

    const api = await getAPIClient();
    const res = await api.put(`admin/products/`, formattedData);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
