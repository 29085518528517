import { ProductProvider, RechargeType } from 'src/lib/types/product';
import SubscriptionForm from '../../../components/Forms/SubscriptionsForm';
import useCreateSubscriptionView from './useView';

export default function CreateSubscriptionView(): JSX.Element {
  const { handleCreateSubscription } = useCreateSubscriptionView();

  return (
    <SubscriptionForm
      defaultValues={{
        productId: '',
        providerProductId: '',
        name: '',
        description: '',
        displayName: '',
        priceUSD: 0,
        provider: ProductProvider.APPLE,
        rechargeType: RechargeType.STICKY_COIN,
        hasFreeTrial: 'false',
        isDaily: 'false',
        rechargeValue: 0,
        subscriptionDuration: 0,
      }}
      onSubmit={handleCreateSubscription}
      title="Create Subscription"
    />
  );
}
