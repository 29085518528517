import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';
import * as yup from 'yup';
import { SubscriptionFormValues } from './types';

const validationSchema = yup.object().shape({
  productId: yup.string().required('Product ID is required'),
  providerProductId: yup.string().required('Provider Product ID is required'),
  provider: yup.string().required('Provider is required'),
  displayName: yup.string().required('Display Name is required'),
  description: yup.string().required('Description is required'),
  priceUSD: yup
    .number()
    .required('Price is required')
    .positive('Price must be a positive number'),
  rechargeType: yup.string().required('Recharge Type is required'),
  rechargeValue: yup
    .number()
    .required('Recharge Value is required')
    .positive('Recharge Value must be a positive number'),
  subscriptionDuration: yup
    .number()
    .required('Subscription Duration is required')
    .positive('Subscription Duration must be a positive number'),
  name: yup.string().required('Name is required'),
  isDaily: yup
    .string()
    .required('Is Daily is required')
    .oneOf(['true', 'false'], 'Is Daily must be true or false'),

  hasFreeTrial: yup
    .string()
    .required('Free Trial is required')
    .oneOf(['true', 'false'], 'Free Trial must be true or false'),
  freeTrialDuration: yup.number().when('hasFreeTrial', {
    is: 'true',
    then: yup
      .number()
      .required('Free Trial Duration is required')
      .positive('Free Trial Duration must be a positive number'),
  }),
  freeTrialRechargeValue: yup.number().when('hasFreeTrial', {
    is: 'true',
    then: yup
      .number()
      .required('Free Trial Recharge Value is required')
      .positive('Free Trial Recharge Value must be a positive number'),
  }),
});

function useSubscriptionFormView({
  defaultValues,
}: {
  defaultValues: SubscriptionFormValues;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<SubscriptionFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  const rawHasFreeTrial = form.watch('hasFreeTrial');
  const hasFreeTrial = rawHasFreeTrial === 'true';

  useEffect(() => {
    if (!hasFreeTrial) {
      form.setValue('freeTrialRechargeValue', 0);
      form.setValue('freeTrialDuration', 0);
    }
  }, [hasFreeTrial, form]);

  return {
    form,
    isSubmitting,
    handleCloseModal,
    hasFreeTrial,
  };
}

export default useSubscriptionFormView;
