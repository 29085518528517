import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchMessageErrors } from '../../services/fetchMessageErrors';

export default function useSGMErrors() {
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,
  };

  const { data, isLoading } = fetchMessageErrors({
    page: params.page,
    size: params.size,

    startDate: `${params.startDate}T00:00:00Z`,
    endDate: `${params.endDate}T23:59:59Z`,
  });

  return {
    data,
    isLoading,
    params,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',

  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.dateTime.split('T')[0],

  userSearchBy: '',
  userQuery: '',

  gameSearchBy: '',
  gameQuery: '',
};

export type FetchSGMErrorsQueryParams = {
  page: number;
  size: number;
  startDate: string;
  endDate: string;
};
