import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ProductType } from 'src/lib/types/product';
import { SubscriptionFormValues } from '../../../components/Forms/SubscriptionsForm/types';
import { SubscriptionEmitter } from '../../../events/SubscriptionEmitter';
import { CreateOrUpdateProduct } from '../../../services/createProduct';

export default function useCreateSubscriptionView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateSubscription = async (values: SubscriptionFormValues) => {
    const { error, data } = await CreateOrUpdateProduct({
      ...values,
      hasFreeTrial: values.hasFreeTrial === 'true',
      isDaily: values.isDaily === 'true',
      type: ProductType.AUTO_RENEWABLE_SUBSCRIPTION,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Subscription Updated!', { variant: 'success' });
      closeModal();

      SubscriptionEmitter.emit('SubscriptionCreatedOrUpdated', data);
    }
  };

  return {
    handleCreateSubscription,
  };
}
