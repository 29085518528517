import PreviewQrCodeModal from 'src/components/shared/QrCodeUrlPreviewer/QrCodeModal';
import useModalStore from 'src/lib/stores/useModalStore';

interface OpenQrCodeModalProps {
  title: string;
  url: string;
  size?: number;
  hideOpenButton?: boolean;
}

export const useQrCodeModal = () => {
  const { openModal } = useModalStore();

  const handleOpenQrCodeModal = ({
    title,
    url,
    size,
    hideOpenButton,
  }: OpenQrCodeModalProps) => {
    openModal(
      <PreviewQrCodeModal
        title={title}
        url={url}
        size={size}
        hideOpenButton={hideOpenButton}
      />
    );
  };

  return { handleOpenQrCodeModal };
};
