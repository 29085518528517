import { Close } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import useTabs from 'src/template/hooks/useTabs';
import { fetchMessageErrors } from '../../services/fetchMessageErrors';
import { fetchMessageLogs } from '../../services/fetchMessageLogs';

enum MessageLogTabs {
  INPUTS_OUTPUTS = 'Inputs/Outputs',
  ERRORS = 'Errors',
}

type SGMMessageLogModalProps = {
  title: string;
  messageUuid: string;
};

export default function SGMMessageLogModal({
  title,
  messageUuid,
}: SGMMessageLogModalProps): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const { data, isLoading } = fetchMessageLogs(messageUuid);
  const { data: errorsData, isLoading: isErrorsLoading } = fetchMessageErrors({
    messageId: messageUuid,
  });

  // Function to calculate response time
  const calculateResponseTime = (requestedAt: string, respondedAt: string) => {
    const requestedDate = new Date(requestedAt);
    const respondedDate = new Date(respondedAt);
    const differenceInMs = respondedDate.getTime() - requestedDate.getTime();
    return (differenceInMs / 1000).toFixed(0); // Convert to seconds and format
  };

  const { currentTab, onChangeTab, setCurrentTab } = useTabs(
    MessageLogTabs.INPUTS_OUTPUTS,
    {
      queryName: 'tab',
    }
  );

  return (
    <Modal open onClose={handleCloseModal}>
      <Card sx={{ minWidth: 600, maxWidth: '90vw' }}>
        <CardHeader
          title={title}
          action={
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
            >
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            <Tab
              disableRipple
              value={MessageLogTabs.INPUTS_OUTPUTS}
              label={MessageLogTabs.INPUTS_OUTPUTS}
            />
            <Tab
              disableRipple
              value={MessageLogTabs.ERRORS}
              label={MessageLogTabs.ERRORS}
            />
          </Tabs>

          {currentTab === MessageLogTabs.INPUTS_OUTPUTS && (
            <>
              <Flex justifyContent={'center'}>
                {isLoading && <CircularProgress />}
                {!isLoading && (data?.length === 0 || !data) && (
                  <Typography variant="body1" color="textSecondary">
                    No logs found
                  </Typography>
                )}
              </Flex>
              {!isLoading &&
                data?.map((log, index) => (
                  <Accordion key={log.id}>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography>{`${index + 1} - Log ID: ${log.id} \n
          (Response Time: ${
            log?.requestedAt && log?.respondedAt
              ? `${calculateResponseTime(
                  log.requestedAt,
                  log.respondedAt
                )} seconds`
              : 'N/A'
          })
          `}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`message-panel-requestData-content`}
                          id={`message-panel-requestData-header`}
                        >
                          <Typography variant="subtitle2" color="textSecondary">
                            Request Data
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                            }}
                          >
                            <code>
                              {JSON.stringify(log.requestData, null, 2)}
                            </code>
                          </pre>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`message-panel-requestData-content`}
                          id={`message-panel-requestData-header`}
                        >
                          <Typography variant="subtitle2" color="textSecondary">
                            Response Data
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                            }}
                          >
                            <code>
                              {JSON.stringify(log.responseData, null, 2)}
                            </code>
                          </pre>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </>
          )}
          {currentTab === MessageLogTabs.ERRORS && (
            <>
              <Flex justifyContent={'center'}>
                {isErrorsLoading && <CircularProgress />}
                {!isErrorsLoading &&
                  (errorsData?.rows?.length === 0 || !errorsData) && (
                    <Typography variant="body1" color="textSecondary">
                      No errors found
                    </Typography>
                  )}
              </Flex>
              {!isErrorsLoading &&
                errorsData?.rows?.map((error, index) => (
                  <Accordion key={error.id}>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography>{`${index + 1} - Error ID: ${
                        error.id
                      }`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        <code>{JSON.stringify(error.error, null, 2)}</code>
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            mt={2}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseModal}
              size="large"
            >
              Close
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
