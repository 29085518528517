import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import UserSessionsStatsTable from '../../components/UserSessionsStatsTable/UserSessionsStatsTable';
import useUserSessionsStats from './useView';

export default function UserSessionsStats() {
  const { data, isLoading, params, generalStatsData } = useUserSessionsStats();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Sessions Analytics"
        links={[{ name: 'Sessions Analytics' }]}
      />
      <UserSessionsStatsTable
        data={data?.rows || []}
        totalCount={data?.count || 0}
        isLoading={isLoading}
        params={params}
        generalStatsData={generalStatsData}
      />
    </Container>
  );
}
