import { ReportEmitter } from 'src/components/modules/Reports/events/ReportEmitter';
import useSWR, { mutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Report } from '../types/report';
import { getFormattedError } from '../utils/error';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

export const fetchReports = (filter: BracketFilterParams, status = 'all') => {
  const statusFilter = status === 'all' ? '' : `&filter[status]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<Report[]>, Error>(
    `admin/report/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
    queryParams: `${generateSearchRequest(filter)}${statusFilter}`,
  };
};

export const fetchReportWithSWR = (uuid: string) => {
  const { data, error } = useSWR<PaginatedResponse<Report>, Error>(
    `admin/report/${uuid}`
  );
  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchReport = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const { data } = await api.get<Report>(`admin/report/${uuid}`);

    return { data };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const updateReport = async (
  uuid: string,
  report: Partial<Report>,
  queryParams: string
) => {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/report/${report.uuid}`, report);

    mutate(`admin/report/${report.uuid}`);
    mutate(`admin/report/${queryParams}`);
    return {
      data: res.data?.body,
      success: true,
      message: 'The report has been updated successfully',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};

export const bulkUpdateReportStatus = async (
  uuids: string[],
  status: string,
  queryParams?: string
) => {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/report/bulk-update-status`, {
      uuids,
      status,
    });

    mutate(`admin/report/${queryParams}`);
    ReportEmitter.emit('ReportUpdated', null);

    return {
      success: true,
      message: 'The reports have been updated successfully',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};
