import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';
import * as yup from 'yup';
import { ConsumableFormValues } from './types';

const validationSchema = yup.object().shape({
  productId: yup.string().required('Product ID is required'),
  providerProductId: yup.string().required('Provider Product ID is required'),
  provider: yup.string().required('Provider is required'),
  displayName: yup.string().required('Display Name is required'),
  description: yup.string().required('Description is required'),
  priceUSD: yup
    .number()
    .required('Price is required')
    .positive('Price must be a positive number'),
  rechargeType: yup.string().required('Recharge Type is required'),
  rechargeValue: yup
    .number()
    .required('Recharge Value is required')
    .positive('Recharge Value must be a positive number'),
  name: yup.string().required('Name is required'),
});

function useConsumableFormView({
  defaultValues,
}: {
  defaultValues: ConsumableFormValues;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<ConsumableFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  return {
    form,
    isSubmitting,
    handleCloseModal,
  };
}

export default useConsumableFormView;
