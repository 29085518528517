import { Product, RechargeType } from 'src/lib/types/product';
import ConsumableForm from '../../../components/Forms/ConsumablesForm';
import useCreateConsumableView from '../Create/useView';

interface UpdateConsumableViewProps {
  consumable: Product;
}

export default function UpdateConsumableView({
  consumable: {
    productId,
    providerProductId,
    name,
    description,
    displayName,
    priceUSD,
    provider,
    rechargeType,
    rechargeValue,
  },
}: UpdateConsumableViewProps): JSX.Element {
  const { handleCreateConsumable } = useCreateConsumableView();

  return (
    <ConsumableForm
      defaultValues={{
        productId,
        providerProductId,
        name,
        description: description || '',
        displayName: displayName || '',
        priceUSD: +priceUSD || 0,
        provider,
        rechargeType: rechargeType || RechargeType.STICKY_COIN,
        rechargeValue: rechargeValue || 0,
      }}
      onSubmit={handleCreateConsumable}
      title="Update Consumable"
    />
  );
}
