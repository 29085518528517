import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';

const Routes = {
  home: '/home',
  collection: (uuid: string) => `/collections/${uuid}`,
  user_rankings: '/user-rankings',
  transaction: (uuid: string) => `/transactions/${uuid}`,
  nft: (nftId: string) => `/nfts/${nftId}`,
  device: (id: string) => `/device/${id}`,
  devices: '/devices',
  dashboard: '/dashboard',
  reset_password: '/auth/reset-password',
  refunds: '/refunds',
  refund: (id: string) => `/refunds/${id}`,
  user_refund: (originalTransactionId: string, userId: string) =>
    `/refunds/${originalTransactionId}/${userId}`,
  old_transactions: '/old-transactions',
  iap: '/iap',
  iap_apple_receipt: (iapId: string) => `/iap/${iapId}/apple-receipt`,
  feedbacks: '/feedbacks',
  reports: '/report',
  communications: '/communications',
  notFound: '/404',
  subscription: (purchaseId: string, userUuid?: string) =>
    `/subscription/${purchaseId}${userUuid ? `/${userUuid}` : ''}`,
  system_uptime: '/system-uptime',
  checks: '/checks',
  subscriptions_sales_report: '/sales/subscriptions-report',
  consumables_sales_report: '/sales/consumables-report',
  consumables_sales_list: (productUuid?: string) => {
    const url = `/sales/consumables-list?${
      productUuid
        ? `orderBy=eventDate&order=desc&page=0&size=25&searchBy=product.uuid&query=${productUuid}`
        : ''
    }`;

    return url;
  },
  subscriptions_sales: '/users/subscriptions-list',
  subscriptions_sales_v2: (productUuid?: string) =>
    `/users/subscriptions-list${
      productUuid ? `?productUuid=${productUuid}` : ''
    }`,
  consumable: (purchaseId: string, userUuid?: string) =>
    `/consumable/${purchaseId}${userUuid ? `/${userUuid}` : ''}`,

  user: (uuid: string) => `/users/${uuid}`,
  users: {
    list: '/users',
    sales: (id: string) => `/users/${id}/sales`,
    management_events: '/users/management-events',
    notes: '/users/notes',
    userLinks: '/users/user-links',
    sessions_analytics: '/users/sessions-analytics',
    sessions: '/users/sessions',
  },

  transactions: {
    pools: '/pools',
    pool: (id: string) => `/pools/${id}`,
    list: '/transactions',
    poolsStatus: '/pools-status',
    payment_requests: '/payment-requests',
    payment_request: (id: string) => `/payment-requests/${id}`,
    nft_pack_delivery: '/nft-pack-delivery',
    nft_pack: (id: string) => `/nft-packs/${id}`,
    coins_delivery: '/coins-delivery',
    coins_expiring: '/coins-expiring',
  },

  products: {
    subscriptions: '/products/subscriptions',
    consumables: '/products/consumables',
    nft_packs: '/objects/nft-packs',
    nft_pack: (uuid: string) => `/products/nft-packs/${uuid}`,
    card_shards: '/products/card-shards',
  },

  objects: {
    collections: '/objects/collections',
    nfts: '/objects/nfts-search/',
    nfts_for_packs: '/objects/nfts-for-packs',
  },

  db_consistency: {
    user_purges: '/user-purges',
    users_without_wallet: '/users-without-wallet',
    wallet_consistency: '/wallet-consistency',
    revenue_events_consistency: '/revenue-events-consistency',
    not_processed_consumables: '/not-processed-consumables',
    nft_ownership: 'nft_ownership',
    admin_nfts_transfer: '/objects/admin-nfts-transfer',
    blockchain_healthchecks: '/blockchain-healthchecks',
  },

  game_center: {
    games: '/game-center/games',
    categories: '/game-center/categories',
    tags: '/game-center/tags',
    analytics: '/game-center/analytics',
    player_analytics: '/game-center/player-analytics',
    sessions: '/game-center/sessions',
    rankings: '/game-center/rankings',
    ratings: '/game-center/ratings',
    feed_analytics: '/game-center/feed-analytics',

    game: {
      view: (gameUuid: string) => `/game-center/games/${gameUuid}`,
      contests: (gameUuid: string) => `/game-center/games/${gameUuid}/contests`,
      rankings: (gameUuid: string) => `/game-center/games/${gameUuid}/rankings`,
      statistic_groups: (gameUuid: string) =>
        `/game-center/games/${gameUuid}/statistic-groups`,
    },

    contest: {
      events: (contestUuid: string) =>
        `/game-center/contests/${contestUuid}/events`,
      assets: (contestUuid: string) =>
        `/game-center/contests/${contestUuid}/assets`,
    },

    contestEvent: {
      rankings: (eventUuid: string) =>
        `/game-center/contest-events/${eventUuid}/rankings`,
    },
  },

  sgm: {
    statistics: '/game-center/sgm/statistics',
    dialogs: '/game-center/sgm/dialogs',
    dialog: (uuid: string) => `/game-center/sgm/dialogs/${uuid}`,
    most_active_users: '/game-center/sgm/most-active-users',

    prompts: '/game-center/sgm/prompts',

    assetCollections: '/game-center/sgm/asset-collections',
    assetCollection: (uuid: string) =>
      `/game-center/sgm/asset-collections/${uuid}`,

    assets: '/game-center/sgm/assets',
    asset: (uuid: string) => `/game-center/sgm/assets/${uuid}`,

    errors: '/game-center/sgm/errors',
  },

  comunity: {
    chat: {
      chat: (chatId: string) => `/comunity/chat/${chatId}`,
      message: (chatId: string, messageId: string) =>
        `/comunity/chat/${chatId}/${messageId}`,
    },
    clubs: '/comunity/clubs',
    club: (clubUuid: string) => `/comunity/clubs/${clubUuid}`,
    clubMembers: (clubUuid: string) => `/comunity/clubs/${clubUuid}/members`,
    clubChats: (clubUuid: string) => `/comunity/clubs/${clubUuid}/chats`,
    clubTopics: (clubUuid: string) => `/comunity/clubs/${clubUuid}/topics`,
  },

  search: {
    searches: `/search/searches`,
    analytics: '/search/analytics',
  },

  configurations: {
    app_configurations: '/app-configurations',
    home_feeds: '/configurations/home-feeds',
    game_feed: '/configurations/feed',
    regions: '/configurations/regions',
    sanctioned_countries: '/configurations/sanctioned-countries',
    collectionsForPacks: '/configurations/collections-for-packs',
    contests: '/configurations/contests',
  },

  channel: {
    channels: '/channels',
    channel: (channelUuid: string) => `/channels/${channelUuid}`,
    stickyChannels: '/sticky-channels/',
    contents: '/channels/contents',
    content: (contentUuid: string) => `/channels/contents/${contentUuid}`,
  },

  submission: {
    submissions: '/submissions',
    submission: (submissionUuid: string) => `/submissions/${submissionUuid}`,
  },

  review: {
    toReview: '/reviews/to-review',
    objectModerations: '/moderations',
    object: (objectType: OBJECT_MODERATION_OBJECT_TYPE | ':objectType') =>
      `/reviews/${objectType}`,
  },

  external: {
    community: {
      post: (postId: number) => `http://community.getstic.ky/post/${postId}`,
    },
  },
} as const;

export default Routes;
